.icon {
	width: $icon-size;
	height: $icon-size;

	i, svg {
		font-size: $icon-size - .75;
	}
}

.icon + .icon-text {
	padding-left: 1rem;
	width: calc(100% - #{$icon-size} - 1);
}

.icon-xl {
	width: $icon-size-xl;
	height: $icon-size-xl;

	i, svg {
		font-size: $icon-size-xl - .75;
	}
}

.icon-xl + .icon-text {
	width: calc(100% - #{$icon-size-xl} - 1);
}

.icon-lg {
	width: $icon-size-lg;
	height: $icon-size-lg;

	i, svg {
		font-size: $icon-size-lg - .75;
	}
}

.icon-lg + .icon-text {
	width: calc(100% - #{$icon-size-lg} - 1);
}

.icon-sm {
	width: $icon-size-sm;
	height: $icon-size-sm;

	i, svg {
		font-size: $icon-size-sm - .75;
	}
}

.icon-sm + .icon-text {
	width: calc(100% - #{$icon-size-sm} - 1);
}

// Extra Small icon

.icon-xs {
	width: $icon-size-xs;
	height: $icon-size-xs;

	i, svg {
		font-size: $icon-size-xs - .75 !important;
	}

    + .icon-text {
    	width: calc(100% - #{$icon-size-xs} - 1);
    }
}


// Icons included in shapes
.icon-shape {
	padding: 12px;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;


	i, svg {
		font-size: 1.25rem;
	}

	&.icon-lg {
		i, svg {
			font-size: 1.625rem;
		}
	}

	&.icon-sm {
		i, svg {
			font-size: .875rem;
		}
	}

	svg {
		width: 30px;
		height: 30px;
	}

}

.backgound-transparent{
	background-color: rgba(255, 255, 255, 0.0)!important;
}

.icon-timeline-centered{
	font-size: 24px;
	display: block;
	width: 24px;
	height: 24px;
	position: relative;
	left: 50%;
	top: 50%;
	margin-left: -12px;
	margin-top: -12px;
}

.standard-background{
    background: linear-gradient(150deg, #7795f8 15%, #6772e5 70%, #555abf 94%); }

.vertical-timeline::before{
	background: rgb(33, 150, 243) !important;
}

@each $color, $value in $theme-colors {
	.icon-shape-#{$color} {
		@include icon-shape-variant(theme-color($color));
	}
}
